import React from 'react'
import Helmet from "react-helmet"
import { withPrefix, graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'

import Layout from '../components/layout';
//import SEO from "../components/seo"

//import MenuList from "../components/menu-list"
import KBreadBox from "../components/k-bread"
import KQuickyMeals from "../components/k-quicky-meals"
import KDelights from "../components/k-delights"
import KMiniBites from "../components/k-mini-bites"
import KLamig from "../components/k-lamig"
//import KInit from "../components/k-mainit"
import KCakes from "../components/k-cakes"
import KCustomCakes from "../components/k-custom-cakes.js"

import banner1 from "../images/k-bread-menu-1.png"
import banner2 from "../images/mini-banner/k-delights-menu.jpg"
import banner3 from "../images/mini-banner/k-quick-menu.jpg"
import banner4 from "../images/mini-banner/k-bites-menu.jpg"
import banner5 from "../images/mini-banner/k-drinks-banner.jpg"
import banner7 from "../images/mini-banner/k-cake-menu.jpg"


const Menu = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProducts {
    nodes {
      id
      productName
      productType
      productThumbImage {
        file {
          url
        }
      }
      productImage {
        file {
          url
        }
      }
      column
    }
  }
      }
  `)

  return (
    <Layout>
      <Helmet>
        <title>Menu</title>
        <link href={withPrefix('site_js/lity.min.css')} rel="stylesheet" />
      </Helmet>
      <div className="menu container mx-auto">
        {/*data.allContentfulProducts.nodes.map((prod) => { return (<li>{prod.productImage !== null ? <img src={prod.productThumbImage.file.url} /> : 'test'} </li>) }) */}
        <h1 className="text-center our-story__title font-title mt-20 mb-10">Menu</h1>
        <img src={banner1} alt="" className="w-full  md:w-11/12 mb-5 mx-auto xl:mt-16" />
        <KBreadBox data={data.allContentfulProducts.nodes.filter(type => type.productType == 'Bread')} title="K's Bread Box" />
        <img src={banner2} alt="" className="w-full  md:w-11/12 mb-5 mx-auto mt-10" />
        <KDelights data={data.allContentfulProducts.nodes.filter(type => type.productType == 'Delight')} title="K's Delight" />
        <img src={banner4} alt="" className="w-full  md:w-11/12 mb-5 mx-auto mt-10" />
        <KMiniBites data={data.allContentfulProducts.nodes.filter(type => type.productType == 'Mini Bites')} title="K's Mini Bites" />
        <img src={banner3} alt="" className="w-full  md:w-11/12 mb-5 mx-auto mt-10" />
        <KQuickyMeals data={data.allContentfulProducts.nodes.filter(type => type.productType == 'Quicky Meal')} title="K's Quicky Meal" />
        <img src={banner5} alt="" className="w-full  md:w-11/12 mb-5 mx-auto mt-10" />
        <KLamig data={data.allContentfulProducts.nodes.filter(type => type.productType == 'Drinks - Cold' || type.productType == 'Drinks - Hot')} title="K's Drinks & Beverages" />
        {/* <img src={banner8} alt="" className="w-full  md:w-11/12 mb-5 mx-auto mt-10"/>
            <KInit title="K's Hot Beverages"/> */}
        <img src={banner7} alt="" className="w-full  md:w-11/12 mb-5 mx-auto mt-10" />
        <KCakes data={data.allContentfulProducts.nodes.filter(type => type.productType == 'Cake')} title="K's Cake" />
        <KCustomCakes data={data.allContentfulProducts.nodes.filter(type => type.productType == 'Custom Cake')} title="K's Made to order cake" />
      </div>
    </Layout>
  )
}

export default Menu