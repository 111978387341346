import React from 'react'

import bread3 from "../images/no-image.jpg"
import sopas from "../images/products/sopas.jpg"
import arozcaldo from "../images/products/arozcaldo.jpg"
import pancitWithPandesal from "../images/products/pancit-w-pandesal-2.jpg"
import pancitcanton from "../images/products/pancit-canton.jpg"
import palabok from "../images/products/palabok.jpg"
import spaghetti from "../images/products/spaghetti.jpg"
import mami from "../images/products/mami.jpg"
import pandesaltreats from "../images/products/pandesal-treats.jpg"

import hmami from "../images/products/hi-res/mami.jpg"
import hpandesaltreats from "../images/products/hi-res/pandesal-treats.jpg"
import hpancitWithPandesal from "../images/products/hi-res/pancit-w-pandesal-2.jpg"
import hpalabok from "../images/products/hi-res/palabok.jpg"
import hpancitcanton from "../images/products/hi-res/pancit-canton.jpg"
import harozcaldo from "../images/products/hi-res/arozcaldo.jpg"
import hsopas from "../images/products/hi-res/sopas.jpg"
import hspaghetti from "../images/products/hi-res/spaghetti.jpg"

const KQuickyMeals = ({ title }) => (
    <div className="menu-list text-center">
        <h3 className="font-title inline menu--list__title mt-10 mx-auto pb-1 text-center">{title}</h3>
        <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 xl:mt-6 product--lists xl:px-10">
                <ul className="mx-5 my-5">
                    <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">Mami</p>
                            <p className="product__desc ml-2"></p>
                        </div>
                        <a href="#" data-lity-target={hmami} data-lity className="w-1/5"> <img src={mami} className="rounded" /> </a>
                        {/* <p className="product__price ml-auto font-bold">12 <span>AED</span></p> */}
                    </li>
                    <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">Pandesal Treats</p>
                            <p className="product__desc ml-2"></p>
                        </div>
                        <a href="#" data-lity-target={hpandesaltreats} data-lity className="w-1/5"> <img src={pandesaltreats} className=" rounded" /> </a>
                        {/* <p className="product__price ml-auto font-bold">12 <span>AED</span></p> */}
                    </li>
                    <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">Pancit Bihon w/ Pandesal</p>
                            <p className="product__desc ml-2"></p>
                        </div>
                        <a href="#" data-lity-target={hpancitWithPandesal} data-lity className="w-1/5"> <img src={pancitWithPandesal} className="rounded" /> </a>
                        {/* <p className="product__price ml-auto font-bold">10 <span>AED</span></p> */}
                    </li>
                    <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">Palabok</p>
                            <p className="product__desc ml-2"></p>
                        </div>
                        <a href="#" data-lity-target={hpalabok} data-lity className="w-1/5"> <img src={palabok} className="rounded" /> </a>
                        {/* <p className="product__price ml-auto font-bold">10 <span>AED</span></p> */}
                    </li>
                </ul>
            </div>
            <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 xl:mt-6 product--lists xl:px-10">
                <ul className="mx-5 my-5">
                    <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">Pancit Canton</p>
                            <p className="product__desc ml-2"></p>
                        </div>
                        <a href="#" data-lity-target={hpancitcanton} data-lity className="w-1/5"> <img src={pancitcanton} className="rounded" /> </a>
                        {/* <p className="product__price ml-auto font-bold">12 <span>AED</span></p> */}
                    </li>
                    <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">Arroz Caldo</p>
                            <p className="product__desc ml-2"></p>
                        </div>
                        <a href="#" data-lity-target={harozcaldo} data-lity className="w-1/5"> <img src={arozcaldo} className="rounded" /> </a>
                        {/* <p className="product__price ml-auto font-bold">7 <span>AED</span></p> */}
                    </li>
                    <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">Sopas</p>
                            <p className="product__desc ml-2"></p>
                        </div>
                        <a href="#" data-lity-target={hsopas} data-lity className="w-1/5"> <img src={sopas} className="rounded" /> </a>
                        {/* <p className="product__price ml-auto font-bold">10 <span>AED</span></p> */}
                    </li>
                </ul>
            </div>
            <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 xl:mt-6 product--lists xl:px-10">
                <ul className="mx-5 my-5">

                    <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">Mixed Pancit</p>
                            <p className="product__desc ml-2"></p>
                        </div>
                        <img src={bread3} className="w-1/5 rounded" />
                        {/* <p className="product__price ml-auto font-bold">10 <span>AED</span></p> */}
                    </li>
                    <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">Lomi</p>
                            <p className="product__desc ml-2"></p>
                        </div>
                        <img src={bread3} className="w-1/5 rounded" />
                        {/* <p className="product__price ml-auto font-bold">10 <span>AED</span></p> */}
                    </li>
                    <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">Spaghetti</p>
                            <p className="product__desc ml-2"></p>
                        </div>
                        <a href="#" data-lity-target={hspaghetti} data-lity className="w-1/5"> <img src={spaghetti} className="rounded" /> </a>
                        {/* <p className="product__price ml-auto font-bold">10 <span>AED</span></p> */}
                    </li>
                </ul>
            </div>
        </div>
    </div>
)

export default KQuickyMeals