import React from 'react'

import bread3 from "../images/no-image.jpg"
import halohalo from "../images/products/halohalo.jpg"

const KLamig = ({ title, data }) => (
    <div className="menu-list text-center">
        <h3 className="font-title inline menu--list__title mt-10 mx-auto pb-1 text-center">{title}</h3>
        <div className="flex flex-wrap">

            <div className="mt-6 w-full md:w-1/3 lg:w-1/3 xl:w-1/3  product--lists xl:px-10">
                <h3 className="font-title inline menu--list__title mt-12 mx-auto pb-1 text-center">Hot Drinks</h3>
                <ul className="mx-5 my-5 mt-8">
                    {data.filter(col => col.column == '1' && col.productType == 'Drinks - Hot').map((prod) => <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">{prod.productName}</p>
                        </div>
                    </li>)}
                </ul>
            </div>
            <div className="mt-6 w-full md:w-2/3 lg:w-2/3 xl:w-2/3  product--lists xl:px-10">
                <h3 className="font-title inline menu--list__title mt-12 mx-auto pb-1 text-center">Cold Drinks</h3>
                <div className=" flex flex-wrap">
                    <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2  product--lists xl:px-10">
                        <ul className="mx-5 my-5 mt-8">
                            {data.filter(col => col.column == '1' && col.productType == 'Drinks - Cold').map((prod) => <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                                <div className=" w-3/4">
                                    <p className="product__name ml-2">{prod.productName}</p>
                                </div>
                            </li>)}
                        </ul>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2  product--lists xl:px-10">
                        <ul className="mx-5 my-5 mt-8">
                            {data.filter(col => col.column == '2' && col.productType == 'Drinks - Cold').map((prod) => <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                                <div className=" w-3/4">
                                    <p className="product__name ml-2">{prod.productName}</p>
                                </div>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default KLamig