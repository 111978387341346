import React from 'react'

import bread3 from "../images/no-image.jpg"
import shakoy from "../images/products/shakoy.jpg"
import toron from "../images/products/toron.jpg"
import maja from "../images/products/maja.jpg"
import sapin from "../images/products/sapin-sapin.jpg"
import maruya from "../images/products/maruya.jpg"
import nilupak from "../images/products/nilupak.jpg"
import casava from "../images/products/casava.jpg"
import biko from "../images/products/biko.jpg"
import carioca from "../images/products/butchi.jpg"
import butchi2 from "../images/products/butchi2.jpg"
import pitchi2x from "../images/products/pitchi2x.jpg"
import okoy from "../images/products/okoy.jpg"
import palitawsalatik from "../images/products/palitaw-sa-latik.jpg"
import bilobilo from "../images/products/bilo-bilo.jpg"
import maruyacassava from "../images/products/maruya-cassava.jpg"
import kutsinta from "../images/products/kutsinta.jpg"
import palitaw from "../images/products/palitaw.jpg"
import putocheese from "../images/products/puto-cheese.jpg"
import putopaw from "../images/products/puto-paw.jpg"
import bibinkankakanin from "../images/products/bibinkan-kakanin.jpg"
import lumpia from "../images/products/lumpia.jpg"
import espasol from "../images/products/espasol.jpg"
import bikoube from "../images/products/biko-ube.jpg"
import tikoy from "../images/products/tikoy.jpg"
import putobigas from "../images/products/puto-bigas.jpg"

import hbibinkankakanin from "../images/products/hi-res/bibinkan-kakanin.jpg"
import hbiko from "../images/products/hi-res/biko.jpg"
import hbikoube from "../images/products/hi-res/biko-ube.jpg"
import hbilobilo from "../images/products/hi-res/bilo-bilo.jpg"
import hbutchi2 from "../images/products/hi-res/butchi2.jpg"
import hcarioca from "../images/products/hi-res/carioca.jpg"
import hcasava from "../images/products/hi-res/casava.jpg"
import hespasol from "../images/products/hi-res/espasol.jpg"
import hkutsinta from "../images/products/hi-res/kutsinta.jpg"
import hlumpia from "../images/products/hi-res/lumpia.jpg"
import hmaja from "../images/products/hi-res/maja.jpg"
import hmaruya from "../images/products/hi-res/maruya.jpg"
import hmaruyacassava from "../images/products/hi-res/maruya-cassava.jpg"
import hnilupak from "../images/products/hi-res/nilupak.jpg"
import hokoy from "../images/products/hi-res/okoy.jpg"
import hpalitaw from "../images/products/hi-res/palitaw.jpg"
import hpalitawsalatik from "../images/products/hi-res/palitaw-sa-latik.jpg"
import hpitchi2x from "../images/products/hi-res/pitchi2x.jpg"
import hputocheese from "../images/products/hi-res/puto-cheese.jpg"
import hputopaw from "../images/products/hi-res/puto-pao.jpg"
import hsapin from "../images/products/hi-res/sapin-sapin.jpg"
import htoron from "../images/products/hi-res/toron.jpg"
import hshakoy from "../images/products/hi-res/shakoy.jpg"
import htikoy from "../images/products/hi-res/tikoy.jpg"
import hputobigas from "../images/products/hi-res/puto-bigas.jpg"

const KDelights = ({ title, data }) => (
    <div className="menu-list text-center">
        <h3 className="font-title inline menu--list__title mt-10 mx-auto pb-1 text-center">{title}</h3>
        <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 xl:mt-6 product--lists xl:px-10">
                <ul className="mx-5 my-5">
                    {data.filter(col => col.column == '1').map((prod) => <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">{prod.productName}</p>
                        </div>
                        <a href="#" data-lity-target='' data-lity className="w-1/5">{prod.productImage !== null ? <img src={prod.productThumbImage.file.url} class="rounded" /> : <img src={bread3} class="rounded" />}</a>
                    </li>)}
                </ul>
            </div>
            <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 xl:mt-6 product--lists xl:px-10">
                <ul className="mx-5 my-5">
                    {data.filter(col => col.column == '2').map((prod) => <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">{prod.productName}</p>
                        </div>
                        <a href="#" data-lity-target='' data-lity className="w-1/5">{prod.productImage !== null ? <img src={prod.productThumbImage.file.url} class="rounded" /> : <img src={bread3} class="rounded" />}</a>
                    </li>)}
                </ul>
            </div>
            <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 xl:mt-6 product--lists xl:px-10">
                <ul className="mx-5 my-5">
                    {data.filter(col => col.column == '3').map((prod) => <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">{prod.productName}</p>
                        </div>
                        <a href="#" data-lity-target='' data-lity className="w-1/5">{prod.productImage !== null ? <img src={prod.productThumbImage.file.url} class="rounded" /> : <img src={bread3} class="rounded" />}</a>
                    </li>)}
                </ul>
            </div>




        </div>
    </div>
)

export default KDelights