import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import bread3 from "../images/no-image.jpg"
import pandesal from "../images/products/pandesal.jpg"
import pandecoco from "../images/products/pandecoco.jpg"
import empanada from "../images/products/empanada.jpg"
import kababayan from "../images/products/kababayan.jpg"
import ensemada from "../images/products/ensemada.jpg"
import mongobread from "../images/products/mongo-bread.jpg"
import maligayabread from "../images/products/maligaya-bread.jpg"
import sausageroll from "../images/products/sausage-roll.jpg"
import cinammon from "../images/products/cinammon.jpg"
import chickenfloss from "../images/products/chicken-floss.jpg"
import ubebar from "../images/products/ube-bar.jpg"
import ubebread from "../images/products/ube-bread.jpg"
import cheesebread from "../images/products/cheese-bread.jpg"
import breadpudding from "../images/products/cheese-pudding.jpg"
import creambread from "../images/products/cream-bread.jpg"
import onionbread from "../images/products/onion-bread.jpg"
import spanishbread from "../images/products/spanish-bread.jpg"
import toastedsiopao from "../images/products/toasted-siopao.jpg"

import hcheesebread from "../images/products/hi-res/cheese-bread.jpg"
import hbreadpudding from "../images/products/hi-res/cheese-pudding.jpg"
import hcinammon from "../images/products/hi-res/cinammon.jpg"
import hcreambread from "../images/products/hi-res/cream-bread.jpg"
import hmongobread from "../images/products/hi-res/mongo-bread.jpg"
import honionbread from "../images/products/hi-res/onion-bread.jpg"
import hpandecoco from "../images/products/hi-res/pandecoco.jpg"
import hpandesal from "../images/products/hi-res/pandesal.jpg"
import hsausageroll from "../images/products/hi-res/sausage-roll.jpg"
import hempanada from "../images/products/hi-res/empanada.jpg"
import hensemada from "../images/products/hi-res/ensemada.jpg"
import hkababayan from "../images/products/hi-res/kababayan.jpg"
import hmaligayabread from "../images/products/hi-res/maligaya-bread.jpg"
import hspanishbread from "../images/products/hi-res/spanish-bread.jpg"
import htoastedsiopao from "../images/products/hi-res/toasted-siopao.jpg"
import hubebar from "../images/products/hi-res/ube-bar.jpg"
import hubebread from "../images/products/hi-res/ube-bread.jpg"
import hchickenfloss from "../images/products/hi-res/chicken-floss.jpg"


const KBreadBox = ({ title, data }) => (
    <div className="menu-list text-center">

        <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 xl:mt-6 product--lists xl:px-10">
                <ul className="mx-5 my-5">
                    {data.filter(col => col.column == '1').map((prod) => <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">{prod.productName}</p>
                        </div>
                        <a href="#" data-lity-target='' data-lity className="w-1/5">{prod.productImage !== null ? <img src={prod.productThumbImage.file.url} class="rounded" /> : <img src={bread3} class="rounded" />}</a>
                    </li>)}
                </ul>
            </div>

            <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 xl:mt-6 product--lists xl:px-10">
                <ul className="mx-5 my-5">
                    {data.filter(col => col.column == '2').map((prod) => <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">{prod.productName}</p>
                        </div>
                        <a href="#" data-lity-target='' data-lity className="w-1/5">{prod.productImage !== null ? <img src={prod.productThumbImage.file.url} class="rounded" /> : <img src={bread3} class="rounded" />}</a>
                    </li>)}
                </ul>
            </div>

            <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 xl:mt-6 product--lists xl:px-10">
                <ul className="mx-5 my-5">
                    {data.filter(col => col.column == '3').map((prod) => <li className="flex flex-wrap justify-between text-left items-center mb-3 pb-2 border-dashed border-b ">
                        <div className=" w-3/4">
                            <p className="product__name ml-2">{prod.productName}</p>
                        </div>
                        <a href="#" data-lity-target='' data-lity className="w-1/5">{prod.productImage !== null ? <img src={prod.productThumbImage.file.url} class="rounded" /> : <img src={bread3} class="rounded" />}</a>
                    </li>)}
                </ul>
            </div>



        </div>
    </div >
)




export default KBreadBox
